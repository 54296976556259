import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import axios from "axios";

// config
import config from "../../config";

// components
import TestimonialDetailBanner from "./TestimonialDetailBanner";
// import { TestimonialCtaForm } from "./TestimonialCtaForm";
import GetInTouch from "../Home/GetInTouch/GetInTouch";

// images
import { Image } from "cloudinary-react";

// css
import * as styles from "../Testimonials/Testimonials.module.css";

export default function TestimonialUserDetails(props) {
  const [loading, setLoading] = useState();
  const [payload, setPayload] = useState(null);
  // const [phone, setPhone] = useState(null);
  // const [email, setEmail] = useState(null);
  // const [name, setName] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const options = {
        method: "get",
        url: config.DX_API_BASE_URL + "/api/testimonials/" + props.id,
        headers: {
          Authorization: `Bearer ${config.JWT_TOKEN}`,
        },
      };

      try {
        setLoading(true);
        const response = await axios(options);
        let data = response.data.results[0];
        let jsonData = {
          ...data,
          userImage: (
            <div>
              <Image
                secure="true"
                cloudName={config.CLOUDINARY_NAME}
                publicId={data.clientPhoto}
                dpr="auto"
                responsive
                width="auto"
                crop="scale"
                responsiveUseBreakpoints="true"
                fetchFormat="webp"
                quality="auto"
                className={styles.bs_img}
              />{" "}
            </div>
          ),
          heading: data.headline,
          location: data.city,
          description: data.headline2,
          readMoreLink: "/reversal-webinar",
        };

        setPayload(jsonData);

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, [props.id]);

  // useEffect(() => {
  //   if (props.clientId) {
  //     getClientData(props.clientId);
  //   }
  // }, [props.clientId]);

  // const getClientData = async clientId => {
  //   const options = {
  //     method: "get",
  //     url: config.DX_API_BASE_URL + "/api/clients/details/" + clientId,
  //     headers: {
  //       Authorization: `Bearer ${config.JWT_TOKEN}`,
  //     },
  //   };

  //   try {
  //     const res = await axios(options);
  //     const user = res.data.results[0];
  //     setName(user.name);
  //     setEmail(user.email);
  //     setPhone(user.phone);
  //   } catch (error) {
  //     console.log("Error While Fetching Client Info", error);
  //   }
  // };

  return (
    <div className="main_div">
      <Helmet>
        <title>Diabetes Quiz | Diabetes Reversal Program | Twin</title>
        <meta
          name="description"
          content="Diabetes Quiz. 1000's have reversed their diabetes using Twin's proven AI based precision treatment."
        />
      </Helmet>
      {loading ? (
        <div className={styles.spiner_container}>
          <Spinner
            animation="border"
            role="status"
            className={styles.spinner}
          />
        </div>
      ) : (
        <>
          <TestimonialDetailBanner {...props} payload={payload} />
          {/* <TestimonialCtaForm
            clientId={props.clientId}
            name={name}
            phone={phone}
            email={email}
            {...props}
          /> */}
          <GetInTouch />
        </>
      )}
    </div>
  );
}
