import React from "react";
import queryString from "query-string";

// seo
import Seo from "../components/Seo";

// App Header
import AppHeader from "../components/AppHeader/AppHeader";
// import AppHeaderTwinApp from "../components/AppHeader/AppHeaderTwinApp";

// components
import TestimonialUserDetails from "../components/Testimonials/testimonials-user-details";
import Footer from "../components/Footer/FooterV1";

const TestimonialUserMemb = props => {
  const { id, appHeader, twinApp, clientId } = queryString.parse(
    props.location.search
  );

  return (
    <div>
      <Seo
        title="Diabetes Quiz | Diabetes Reversal Program"
        description="Diabetes Quiz. 1000's have reversed their diabetes using Twin's proven AI based precision treatment."
      />

      {/* Start ---> Header for Twin App */}
      {/* {appHeader === "no" && twinApp === "yes" && (
        <AppHeaderTwinApp
          text=""
          isBack={true}
          backUrl={`/testimonials/?appHeader=${appHeader}&twinApp=${twinApp}&clientId=${clientId}`}
          bgColor="bgLightgray"
        />
      )} */}
      {appHeader === "mobile" && twinApp === "yes" && (
        <AppHeader
          text=""
          isBack={true}
          backUrl={`/testimonials/?appHeader=${appHeader}&twinApp=${twinApp}&clientId=${clientId}`}
        />
      )}
      {/* End ---> Header for Twin App */}

      {props.location.search && (
        <div>
          <TestimonialUserDetails
            id={id}
            appHeader={appHeader}
            twinApp={twinApp}
            clientId={clientId}
          />
          <Footer isHome={false} />
        </div>
      )}
    </div>
  );
};

export default TestimonialUserMemb;
