import React, { useState } from "react";

import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "../../components/Testimonials/Testimonials.module.css";
import { Image } from "cloudinary-react";
import config from "../../config";
import { noOfDayAndMonthConversion } from "../../utils";

const TestimonialDetailBanner = props => {
  const [readMore, setReadMore] = useState(false);
  const url = `/testimonials/`;
  // console.log(`/testimonials/${props.appHeader && "?appHeader="+props.appHeader}${props.twinApp && "&twinApp="+props.twinApp}`);

  return (
    <div className="bgLightgray">
      <div
        className={
          props.appHeader === "no" && props.twinApp === "yes"
            ? `container twinContainer pb-5 ${styles.testiDtls_twinApp}`
            : "container twinContainer pt-4 pb-5"
        }
      >
        <div className="d-none d-md-flex row">
          <div className="col-12 d-flex align-items-center">
            <Link className="navLink" to={url}>
              <div role="presentation" className={styles.breadCrumb}>
                <StaticImage
                  src="../../images/home/breadCrumb.svg"
                  alt="breadCrumb"
                  placeholder="blurred"
                />
              </div>
            </Link>
            <div className={styles.successStor}>Customer success stories</div>
          </div>
          <div className="col-12">
            <div className={styles.progressTxt}>Member progress</div>
          </div>

          <div className="col-12">
            <hr />
          </div>

          <div className="col-5 pe-5 offset-1">
            <div className="row">
              <div className="col-12 pt-5 pb-4">
                {props.payload && props.payload.userImage}
              </div>
              <div className="col-12 pb-1">
                <div className={styles.heading1}>
                  {props.payload && props.payload.headline}
                </div>
              </div>
              <div className="col-12 pb-3">
                <div className={styles.testimonialSubHeader}>
                  {props.payload && props.payload.age} years old,{" "}
                  {props.payload && props.payload.city}
                </div>
              </div>

              <div className="col-12">
                <div className={styles.review2}>
                  {readMore
                    ? props.payload && props.payload.review.substr(0, 200)
                    : props.payload &&
                      props.payload.review.substr(0, 200) + "..."}
                  <span className="collapse" id="collapseExample">
                    {props.payload &&
                      props.payload.review.substr(
                        200,
                        props.payload.review.length
                      )}
                  </span>
                </div>
              </div>
              <div className="col-12 pb-3">
                <div
                  data-bs-toggle="collapse"
                  href="#collapseExample"
                  role="presentation"
                  onClick={() => setReadMore(!readMore)}
                  // aria-expanded="false"
                  aria-controls="collapseExample"
                  className={`${styles.readMore} cursorPointer`}
                >
                  {!readMore ? "Read more" : "Read less"}
                </div>
              </div>
            </div>
          </div>
          <div className="col-5">
            <div className="row pt-5">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-6 col-md-6 col-lg-6">
                    <p className={styles.custText4}>Start of the program</p>
                  </div>
                  <div className="col-6 col-md-6 col-lg-6 d-flex justify-content-end">
                    <span className={styles.custText4}>
                      {props.payload &&
                        props.payload.noOfDaysSugarNormalised &&
                        noOfDayAndMonthConversion(
                          props.payload.noOfDaysSugarNormalised
                        )}{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {props.payload && props.payload.bloodSugarLevelAfter && (
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="row align-items-center">
                    <div className="d-flex">
                      <div className="pe-3 mt-2">
                        <span className="smallIcon icon-drop" />
                      </div>
                      <div className="d-flex mt-2">
                        <p className={styles.progText1}>Blood Sugar: </p>
                        <p className={`${styles.progText2} ps-1`}>
                          {props.payload && props.payload.bloodSugarLevelBefore}
                        </p>
                      </div>
                      <div className="ps-2 pt-1 pb-2 mt-2 pe-2">
                        <StaticImage
                          src="../../images/home/bigArrow.svg"
                          alt=""
                          placeholder="blurred"
                        />
                      </div>
                      <div
                        className={`rounded-pill d-flex align-items-center justify-content-center ${styles.greenPillBox} ms-2`}
                      >
                        <span>
                          {props.payload && props.payload.bloodSugarLevelAfter}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {props.payload && props.payload.noOfMedsBefore && (
              <div className="row pb-5">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="row align-items-center">
                    <div className="d-flex">
                      <div className="pe-3 mt-2">
                        <span className="smallIcon icon-tablet" />
                      </div>
                      <div className="d-flex mt-2">
                        <p className={styles.progText1}>Medicines: </p>
                        <p className={`${styles.progText2} ps-1`}>
                          {props.payload && props.payload.noOfMedsBefore}
                        </p>
                      </div>
                      <div className="ps-2 pt-1 mt-2 pe-2">
                        <StaticImage
                          src="../../images/home/bigSmallArrow.svg"
                          alt=""
                          placeholder="blurred"
                        />
                      </div>
                      <div
                        className={`rounded-pill d-flex align-items-center justify-content-center ${styles.greenPillBox} ms-4`}
                      >
                        <span>
                          {props.payload && props.payload.noOfMedsAfter}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className={`row pt-1 pb-4`}>
              <div className="col-12 pb-5">
                {props.payload && props.payload.graphLink && (
                  <div className={styles.imgCo}>
                    <Image
                      secure="true"
                      cloudName={config.CLOUDINARY_NAME}
                      publicId={props.payload && props.payload.graphLink}
                      dpr="auto"
                      responsive
                      width="auto"
                      crop="scale"
                      responsiveUseBreakpoints="true"
                      fetchFormat="webp"
                      quality="auto"
                      className={styles.imgCo}
                    />{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-1"></div>

          <div className="col-12 pt-4">
            <hr />
          </div>
        </div>

        <div className="row d-md-none">
          <div
            className={
              props.appHeader === "no" && props.twinApp === "yes"
                ? `${styles.testiDtls_backBtn_twinApp}`
                : "row"
            }
          >
            {(props.appHeader === "no" || props.appHeader === "mobile") &&
            props.twinApp === "yes" ? null : (
              <div className="col-12 d-md-none pb-3">
                <Link className="navLink" to={url}>
                  <span role={"presentation"} className="icon-back smallIcon" />
                </Link>
              </div>
            )}
          </div>
          <div className="row justify-content-center">
            <div className="col-12 px-4 pb-2">
              <div className={styles.testimonialHeader}>
                {props.payload && props.payload.headline}
              </div>
            </div>
            <div className="col-12 d-flex pb-3 justify-content-center">
              <div className={styles.testimonialSubHeader}>
                {props.payload && props.payload.age} years old,{" "}
                {props.payload && props.payload.city}
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center pb-5">
              <div>{props.payload && props.payload.userImage}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="row">
                <div className="col-6 col-md-6 col-lg-6">
                  <p className={styles.custText4}>Start of the program</p>
                </div>
                <div className="col-6 col-md-6 col-lg-6 d-flex justify-content-end">
                  <span className={styles.custText4}>
                    {props.payload &&
                      props.payload.noOfDaysSugarNormalised &&
                      noOfDayAndMonthConversion(
                        props.payload.noOfDaysSugarNormalised
                      )}{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {props.payload && props.payload.bloodSugarLevelAfter && (
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="row align-items-center">
                  <div className="d-flex">
                    <div className="pe-3 mt-2">
                      <span className="smallIcon icon-drop" />
                    </div>
                    <div className="d-flex mt-2">
                      <p className={styles.progText1}>Blood Sugar: </p>
                      <p className={`${styles.progText2} ps-1`}>
                        {props.payload && props.payload.bloodSugarLevelBefore}
                      </p>
                    </div>
                    <div className="ps-2 pt-1 pb-2 mt-2 pe-2">
                      <StaticImage
                        src="../../images/home/bigArrow.svg"
                        alt=""
                        placeholder="blurred"
                      />
                    </div>
                    <div
                      className={`rounded-pill d-flex align-items-center justify-content-center ${styles.greenPillBox} ms-2`}
                    >
                      <span>
                        {props.payload && props.payload.bloodSugarLevelAfter}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {props.payload && props.payload.noOfMedsBefore && (
            <div className="row pb-5">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="row align-items-center">
                  <div className="d-flex">
                    <div className="pe-3 mt-2">
                      <span className="smallIcon icon-tablet" />
                    </div>
                    <div className="d-flex mt-2">
                      <p className={styles.progText1}>Medicines: </p>
                      <p className={`${styles.progText2} ps-1`}>
                        {props.payload && props.payload.noOfMedsBefore}
                      </p>
                    </div>
                    <div className="ps-2 pt-1 mt-2 pe-2">
                      <StaticImage
                        src="../../images/home/bigSmallArrow.svg"
                        alt=""
                        placeholder="blurred"
                      />
                    </div>
                    <div
                      className={`rounded-pill d-flex align-items-center justify-content-center ${styles.greenPillBox} ms-4`}
                    >
                      <span>
                        {props.payload && props.payload.noOfMedsAfter}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={`row pt-1 pb-4`}>
            <div className="col-12">
              {props.payload && props.payload.graphLink && (
                <div className={styles.imgCo}>
                  <Image
                    secure="true"
                    cloudName={config.CLOUDINARY_NAME}
                    publicId={props.payload && props.payload.graphLink}
                    dpr="auto"
                    responsive
                    width="auto"
                    crop="scale"
                    responsiveUseBreakpoints="true"
                    fetchFormat="webp"
                    quality="auto"
                    className={styles.imgCo}
                  />{" "}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 px-3 pb-5 d-flex justify-content-center">
              <div className={styles.review}>
                {props.payload && props.payload.review}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialDetailBanner;
